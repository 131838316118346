import LocalizedStrings from "localized-strings";
import contacts from "./contacts";

const strings = new LocalizedStrings(Object.freeze({
	en: {
		actions: "Actions",
		add: "Add",
		administrator: "Administrator",
		administrators: "Administrators",
		allRightsAreReserved: "All rights are reserved",
		alreadyHaveAnAccount: "Already have an account?",
		areYouSureYouWantToProceed: "Are you sure you want to proceed with the requested action?",
		back: "Back",
		cancel: "Cancel",
		change: "Change",
		changeRemoveCredentials: "Change/remove credentials",
		checkItOutNow: "Check it out now",
		checkTheTechnicalDocumentationOnPostman: "Check the technical documentation on Postman",
		companyProfileURL: "https://www.jogroup.eu/wp-content/uploads/company-profile/HT-Apps-Company-Profile-eng.pdf",
		companyYears: "© HT s.r.l. 2004-{0}",
		confirm: "Confirm",
		confirmPassword: "Confirm password",
		contactUs: "Contact us",
		contactUsWeWillBeHappyToHelpYou: "Contact us, we will be happy to help you.",
		contacts: "Contacts",
		cookiePolicy: "Cookie policy",
		cookiePolicyURL: "https://www.ht-apps.eu/en/cookie-policy/",
		createdByCompany: "Created by Moka Adv",
		credentialsTooltipText: "The user name and password are optional, and allow saving xAPI/SCORM.",
		dashboard: "Dashboard",
		delete: "Delete",
		deleteLMS: "Delete LMS",
		didntYoufindWhatYouWereLookingFor: "Didn't you find what you were looking for?",
		disable: "Disable",
		discoverHowToMakeTheMostOfULOI: "Discover how to make the most of the Universal Learning Object Integrator (ULOI) APIs.",
		dontHaveAnAccountYet: "Don't have an account yet?",
		downloadNow: "Download now",
		downloadULOIHandbook: "Download the ULOI handbook.",
		doYouWantToKnowMore: "Do you want to know more?",
		edit: "Edit",
		editAdminX: "Edit administrator - {0}",
		editLMS: "Edit LMS",
		editLMSX: "Edit LMS - {0}",
		email: "E-mail",
		emailNotRegistered: "E-mail not registered.",
		emailSuccessfullySent: "E-mail successfully sent.",
		enable: "Enable",
		enterANewPasswordForX: "Enter a new password for {0}",
		enterYourEmailToResetYourPassword: "Enter your e-mail to reset your password",
		error: "error",
		facebookURL: "https://www.facebook.com/HT.Apps.WebApplications.Agritech/",
		faq01_question: "1. What is ULOI?",
		faq01_answer: "<p>The <strong>Universal Learning Object Integrator</strong> (<strong>ULOI</strong>) is a data interchange module primarily designed to connect <strong>educational objects</strong> with online learning platforms, commonly known as <strong>Learning Management Systems</strong> (<strong>LMS</strong>). ULOI is capable of monitoring everything, not just learning objects.</p><p>In its primary application, within the domain of e-learning platforms, ULOI can track, for instance, <strong>2D/3D objects</strong>, <strong>scores</strong>, <strong>quiz responses</strong>, <strong>time</strong>, and even the <strong>actions</strong> performed by characters in an educational video game (<strong>serious game</strong>).</p><p>In summary, ULOI serves as a <strong>data interchange bridge</strong>, delivered following the <strong>Software as a Service</strong> (<strong>SaaS</strong>) model, which provides full programming flexibility, enabling extensive customisation and monitoring of any digital resource.</p>",
		faq02_question: "2. How do I start a work session on ULOI?",
		faq02_answer: "To do so, you need to obtain a temporary access code called <strong>authCode</strong>. Once the code has been acquired, it will be possible to link the learning object (such as a 3D application) to the chosen platform.",
		faq03_question: "3. How can I monitor the progress of the users on my online platform?",
		faq03_answer: "<p>To add a user or a learner within <strong>ULOI</strong>, you only need to have <strong>two pieces of information</strong>:</p><ul><li><strong>User ID</strong>: the user's unique identifier.</li><li><strong>Application ID</strong>: the content (educational in the case of LMS) to which the user is connected.</li></ul><p>The operation is automatically completed by the platform itself.</p>",
		faq04_question: "4. Is the process secure?",
		faq04_answer: "Of course! To access <strong>ULOI</strong>, administrators are provided with an <strong>access token</strong>, essentially a digital key that allows them to use the platform's functions in a secure way. The token can be refreshed without having to re-enter credentials each time.",
		faq05_question: "5. What data can I save and monitor with ULOI?",
		faq05_answer: "<p>With <strong>ULOI</strong> you can:</p><ul><li><strong>save data</strong> related to content usage (educational content, in this case), such as user progress or their interactions;</li><li><strong>monitor data</strong> at any time to analyse the trend.</li></ul>",
		faq06_question: "6. Which e-learning standards does ULOI support? ",
		faq06_answer: "<p><strong>ULOI</strong> is compatible with the leading online learning standards:</p><ul><li><strong>SCORM</strong>: used to track interactions with educational contents.</li><li><strong>xAPI</strong>: a more advanced standard that records detailed learning experiences.</li></ul>",
		faq07_question: "7. How do I add a new LMS to ULOI?",
		faq07_answer: "<p>To integrate a new LMS platform into <strong>ULOI</strong>, you will need the following basic information:</p><ul><li><strong>system name</strong>;</li><li><strong>web address</strong> (<strong>URL</strong>);</li><li><strong>content tracking type</strong> (e.g. SCORM or xAPI).</li></ul>",
		faq08_question: "8. What should I do if an error message appears?",
		faq08_answer: "<p>Some common errors include:</p><ul><li><strong>invalid code</strong>: this occurs when certain information is missing or if the code has expired.</li><li><strong>password not accepted</strong>: the password must be secure, containing at least 12 characters, including special ones.</li></ul><p>In such cases, verify the entered data and try again.</p>",
		faq09_question: "9. Where can I find more detailed technical information?",
		faq09_answer: "For more technical and up-to-date details, we recommend you consult the <strong>official technical documentation</strong> of the Universal Learning Object Integrator, available at this <a href=\"https://uloi.cloud/uloi-postman-technical-documents-api-test\" target=\"_blank\">link</a>.",
		faq10_question: "10. How do I contact ULOI technical support?",
		faq10_answer: `<p>For support, you can contact us through the following channels:</p><ul><li>Phone ➜ <a href="${contacts.phoneNumberURI}" target="_blank">${contacts.phoneNumber}</a></li><li>WhatsApp ➜ <a href="${contacts.whatsappURL}" target="_blank">Support</a></li><li>Website ➜ <a href="${contacts.website}" target="_blank">${contacts.website}</a></li></ul><p>The HT Apps team is at your disposal for any questions or requests.</p>`,
		faqs: "FAQs",
		feature1Text: "ULOI allows you to create captivating and innovative training courses.",
		feature1Title: "Innovation",
		feature2Text: "The user-friendly interface makes your life easier.",
		feature2Title: "Intuitive interface",
		feature3Text: "You will be able to effectively track your resources, such as virtual reality (VR) modules and serious games.",
		feature3Title: "Effective tracking",
		features: "Features",
		featuresText: "ULOI is able to integrate both 2D and 3D video games and any other type of learning object into your LMS platform.",
		fillInAllFieldsCorrectly: "Fill in all fields correctly",
		firstName: "First name",
		flipboardURL: "https://flipboard.com/@JOGroup",
		forgotPassword: "Forgot password?",
		forTheLatestUpdatesAccessTheOfficialDocumentation: "For the latest updates regarding the ULOI API, access the official technical documentation on Postman.",
		goBack: "Go back",
		googleNewsURL: "https://news.google.com/s/CBIwwcfU7LMB",
		handbook: "Handbook",
		hiHowCanWeHelpYou: "Hi, how can we help you?",
		hide: "Hide",
		home: "Home",
		iAcceptThePrivacyPolicy: `I accept the <a href="{0}" target="_blank" rel="noreferrer">privacy policy</a>`,
		id: "ID",
		ifWeHaventConvincedYou: "If we haven't convinced you, read the opinion of those who have already tried ULOI",
		invalidEmailFormat: "Invalid e-mail format",
		invalidLink: "Invalid link",
		invalidPhoneFormat: "Invalid phone format",
		invalidURLFormat: "Invalid URL format",
		itSeemsYouAreLost: "It seems you are lost…",
		justNow: "Just now",
		keepReading: "Keep reading",
		linkExpired: "Link expired",
		linkedInURL: "https://it.linkedin.com/company/ht-apps",
		lms: "LMS",
		lmsConflictNameText: "An LMS with the same name is already associated with this administrator.",
		lmsID: "LMS ID",
		logIn: "Log in",
		logInToYourULOIAccount: "Log in to your ULOI account",
		logOut: "Log out",
		loginFailed: "Log-in failed",
		logo: "Logo",
		message: "Message",
		metaDescriptionText: "ULOI is a data interchange module developed by HT Apps. ULOI allows LMS owners to track learning objects, such as video games. Read more.",
		name: "Name",
		newLMS: "New LMS",
		newUserName: "New user name",
		newPassword: "New password",
		next: "Next",
		noResults: "No results",
		notifications: "Notifications",
		numAdmins: "{0}/{1} administrators",
		ok: "OK",
		oopsThatsNotGood: "Oops! That's not good.",
		operatingOffice: "Operating office",
		operatingOfficeAddressPart1: "Via Duca degli Abruzzi, 30/36",
		operatingOfficeAddressPart2: "95127 Catania, Italy",
		or: "or",
		password: "Password",
		passwordIsRequiredWhenUserNameIsPresent: "Password is required when user name is present.",
		passwordIsTooWeak: "Password is too weak",
		passwordRequirementsText: "Passwords must contain at least:<ul><li>12 characters</li><li>1 uppercase letter</li><li>1 lowercase letter</li><li>1 number</li><li>1 special character: @.:,;'\"€£$+-_*=<>°#%?!&/\\()|[]{}^§~</li></ul>",
		passwordsDontMatch: "Passwords don't match",
		phone: "Phone",
		postmanDocumentationURL: "/uloi-postman-technical-documents-api-test",
		privacyPolicy: "Privacy policy",
		privacyPolicyURL: "https://www.ht-apps.eu/en/privacy-statement/",
		readOurReviews: "Read our reviews on {0}",
		registeredOffice: "Registered office",
		registeredOfficeAddressPart1: "Via Mario Sangiorgi, 37",
		registeredOfficeAddressPart2: "95129 Catania, Italy",
		remove: "Remove",
		requestFailed: "The request failed.",
		requiredField: "Required field",
		resetPassword: "Reset password",
		save: "Save",
		scorm: "SCORM",
		search: "Search",
		serverError: "Server error.",
		settings: "Settings",
		show: "Show",
		showUsers: "Show users",
		signUp: "Sign up",
		signUpNow: "Sign up now",
		signupAlreadyRegisteredText: "An administrator with this e-mail address is already registered.",
		signupSuccessfulText: "Your registration was successful.<br />Wait for your profile to be activated.",
		signupUnsuccessfulText: "Check your registration data again.",
		submit: "Submit",
		success: "Success",
		surname: "Surname",
		termsOfUse: "Terms of use",
		termsOfUseURL: "https://shop.ht-apps.eu/en/general-condition-of-sales/",
		testimonial1Avatar: "/images/testimonial1_en.jpg",
		testimonial1Comment: "Thanks to ULOI, I added 2D and 3D games to our e-learning platform. This makes the training more fun and interesting. The easy way we connected things helped us make our classes better.",
		testimonial1FirstName: "Loraine",
		testimonial1Surname: "Nguyen",
		testimonial2Avatar: "/images/testimonial2_en.jpg",
		testimonial2Comment: "ULOI has changed the way we teach and how we teach. I liked how easy it was to use and how well it worked with the xAPI standard. I didn't think adding it to our training system would be so easy and fast!",
		testimonial2FirstName: "Ferdinand",
		testimonial2Surname: "Rossi",
		testimonial3Avatar: "/images/testimonial3_en.jpg",
		testimonial3Comment: "The discovery of ULOI saved me from having to completely alter our teaching approach. Our lessons were greatly enhanced by the ease and quick incorporation of novel training materials into our platform by ULOI.",
		testimonial3FirstName: "Sydne",
		testimonial3Surname: "Foster",
		testimonials: "Testimonials",
		theFieldsMarkedWithAnAsteriskAreRequired: "The fields marked with an asterisk are required",
		threeDeeAppId: "3D app ID",
		trustpilotURL: "https://ie.trustpilot.com/review/ht-apps.eu?utm_medium=trustbox&utm_source=MicroReviewCount",
		tutorial: "Tutorial",
		tutorialName: "tutorial-en.pdf",
		tutorialPath: "/handbooks/uloi-handbook-EN-rev1.3.pdf",
		type: "Type",
		uloi: "ULOI",
		uloiHasAlreadyBeenChosen: "ULOI has already been chosen by customers all over the world.",
		uloiText: "Universal Learning Object Integrator (ULOI) of HT Apps is a SaaS platform designed to take your learning management system (LMS) experience to the next level in terms of versatility and effectiveness.",
		unauthorisedRequest: "Unauthorised request.",
		url: "URL",
		userID: "User ID",
		userNameIsRequiredWhenPasswordIsPresent: "User name is required when password is present.",
		universalLearningObjectIntegrator: "Universal Learning Object Integrator",
		vatNoX: "VAT no. 04125270878",
		warning: "Warning",
		welcomeUser: "Welcome, {0}",
		whyChooseULOI: "Why choose ULOI",
		whyChooseULOIText: "Because it allows your LMS platforms to seamlessly integrate different modules with each other, such as 2D/3D games and web-based software, standardising the tracking mode with that of the SCORM and xAPI standards.",
		xapi: "xAPI",
		youtubeTutorialTitle: "ULOI - How to set up your LMS to track Learning Objects",
		youtubeTutorialURL: "https://www.youtube.com/embed/GpoAvlvqcKM",
		youtubeURL: "https://www.youtube.com/watch?v=vgDuPb_20Yc",
		youMustAcceptThePrivacyPolicy: "You must accept the privacy policy to continue",
		yourMessageWasSentSuccessfully: "Your message was sent successfully.",
		yourPasswordHasBeenResetSuccessfully: "Your password has been reset successfully."
	},
	it: {
		actions: "Azioni",
		add: "Aggiungi",
		administrator: "Amministratore",
		administrators: "Amministratori",
		allRightsAreReserved: "Tutti i diritti sono riservati",
		alreadyHaveAnAccount: "Hai già un account?",
		areYouSureYouWantToProceed: "Sei sicuro di voler procedere con l'azione richiesta?",
		back: "Indietro",
		cancel: "Annulla",
		change: "Cambia",
		changeRemoveCredentials: "Cambia/rimuovi credenziali",
		checkItOutNow: "Consultala ora",
		checkTheTechnicalDocumentationOnPostman: "Consulta la documentazione tecnica su Postman",
		companyProfileURL: "https://www.jogroup.eu/wp-content/uploads/company-profile/HT-Apps-Company-Profile-ita.pdf",
		companyYears: "© HT s.r.l. 2004-{0}",
		confirm: "Conferma",
		confirmPassword: "Conferma password",
		contactUs: "Contattaci",
		contactUsWeWillBeHappyToHelpYou: "Contattaci, saremo felici di aiutarti.",
		contacts: "Contatti",
		cookiePolicy: "Politica sui cookie",
		cookiePolicyURL: "https://www.ht-apps.eu/cookie-policy/",
		createdByCompany: "Creato da Moka Adv",
		credentialsTooltipText: "Il nome utente e la password sono opzionali, e consentono il salvataggio di xAPI/SCORM.",
		dashboard: "Dashboard",
		delete: "Elimina",
		deleteLMS: "Elimina LMS",
		didntYoufindWhatYouWereLookingFor: "Non hai trovato quello che cercavi?",
		disable: "Disable",
		discoverHowToMakeTheMostOfULOI: "Scopri come sfruttare al meglio le API dell'Universal Learning Object Integrator (ULOI).",
		dontHaveAnAccountYet: "Non hai ancora un account?",
		downloadNow: "Scarica ora",
		downloadULOIHandbook: "Scarica il manuale di ULOI.",
		doYouWantToKnowMore: "Vuoi saperne di più?",
		edit: "Modifica",
		editAdminX: "Modifica amministratore - {0}",
		editLMS: "Modifica LMS",
		editLMSX: "Modifica LMS - {0}",
		email: "E-mail",
		emailNotRegistered: "E-mail non registrata.",
		emailSuccessfullySent: "E-mail inviata con successo.",
		enable: "Abilita",
		enterANewPasswordForX: "Inserisci una nuova password per {0}",
		enterYourEmailToResetYourPassword: "Inserisci la tua email per reimpostare la password",
		error: "errore",
		facebookURL: "https://www.facebook.com/HT.Apps.WebApplications.Agritech/",
		faq01_question: "1. Che cos'è ULOI?",
		faq01_answer: "<p>L'<strong>Universal Learning Object Integrator</strong> (<strong>ULOI</strong>) è un modulo di interscambio dati pensato principalmente per mettere in collegamento gli <strong>oggetti didattici</strong> con le piattaforme di formazione online, dette anche <strong>Learning Management Systems</strong> (<strong>LMS</strong>). ULOI può monitorare tutto e non soltanto i learning object.</p><p>Nello scenario principale, il mondo delle piattaforme di formazione a distanza, l'ULOI può monitorare, ad esempio, <strong>oggetti 2D/3D</strong>, <strong>punteggi</strong>, <strong>risposte a quiz</strong>, il <strong>tempo</strong> e persino le <strong>azioni</strong> svolte dai personaggi di un videogioco educativo (<strong>serious game</strong>).</p><p>In sintesi, l'ULOI è un <strong>bridge di interscambio dati</strong>, erogato seguendo il modello del <strong>Software as a Service</strong> (<strong>SaaS</strong>), che dà piena libertà di programmazione, garantendo una personalizzazione estrema e il monitoraggio di qualsiasi risorsa informatica.</p>",
		faq02_question: "2. Come avvio una sessione di lavoro sull'ULOI?",
		faq02_answer: "Per farlo, occorre ottenere un codice di accesso temporaneo chiamato <strong>authCode</strong>. Una volta ottenuto il codice, sarà possibile collegare l'oggetto didattico (ad esempio un'applicazione 3D) con la piattaforma prescelta.",
		faq03_question: "3. Come posso monitorare i progressi degli utenti che utilizzano la mia piattaforma online?",
		faq03_answer: "<p>Per aggiungere un utente o un corsista all'interno dell'<strong>ULOI</strong> dovrai avere semplicemente due informazioni:</p><ul><li><strong>ID dell'utente</strong>: il numero che identifica l'utente.</li><li><strong>ID dell'applicazione</strong>: il contenuto (didattico nel caso degli LMS) a cui l'utente si collega.</li></ul><p>L'operazione viene completata automaticamente dalla stessa piattaforma.</p>",
		faq04_question: "4. Il processo è sicuro?",
		faq04_answer: "Certo! Per accedere all'<strong>ULOI</strong>, gli amministratori riceveranno un <strong>token di accesso</strong>, cioè una sorta di chiave digitale che permette di usare le funzioni della piattaforma in modo sicuro. È possibile aggiornare il token senza dover, ogni volta, reinserire le credenziali.",
		faq05_question: "5. Quali dati posso salvare e monitorare con l'ULOI?",
		faq05_answer: "<p>Con l'<strong>ULOI</strong> potrai:</p><ul><li><strong>salvare dati</strong> relativi all'uso dei contenuti (didattici nel caso specifico), come i progressi degli utenti o le loro interazioni;</li><li><strong>monitorare dati</strong> in qualsiasi momento per comprenderne l'andamento.</li></ul>",
		faq06_question: "6. Quali standard e-learning supporta l'ULOI?",
		faq06_answer: "<p>L'<strong>ULOI</strong> è compatibile con i principali standard per l'apprendimento online:</p><ul><li><strong>SCORM</strong>: usato per tracciare l'interazione con i contenuti didattici.</li><li><strong>xAPI</strong>: uno standard più avanzato che registra in dettaglio le esperienze di apprendimento.</li></ul>",
		faq07_question: "7. Come aggiungo un nuovo sistema LMS sull'ULOI?",
		faq07_answer: "<p>Per aggiungere una nuova piattaforma LMS nell'<strong>ULOI</strong>, ti serviranno alcune informazioni base:</p><ul><li><strong>nome del sistema</strong>;</li><li><strong>indirizzo web</strong> (<strong>URL</strong>);</li><li><strong>tipo di tracciamento dei contenuti</strong> (ad esempio SCORM o xAPI).</li></ul>",
		faq08_question: "8. Cosa devo fare se appare un messaggio di errore?",
		faq08_answer: "<p>Alcuni errori comuni includono:</p><ul><li><strong>codice non valido</strong>: accade nel caso in cui alcune informazioni risultino mancanti o se il codice è scaduto;</li><li><strong>password non accettata</strong>: la password dev'essere sicura, contenere quindi almeno 12 caratteri, inclusi quelli speciali.</li></ul><p>In questi casi, verifica i dati inseriti e riprova.</p>",
		faq09_question: "9. Dove posso trovare informazioni tecniche più dettagliate?",
		faq09_answer: "Per dettagli più tecnici e aggiornati, ti consigliamo di consultare la <strong>documentazione tecnica ufficiale</strong> dell'Universal Learning Object Integrator, reperibile a questo <a href=\"https://www.uloi.cloud/uloi-documentazione-tecnica-postman-api-test\" target=\"_blank\">link</a>.",
		faq10_question: "10. Come contatto il supporto tecnico dell'ULOI?",
		faq10_answer: `<p>Per ricevere aiuto, puoi contattarci in vari modi:</p><ul><li>Telefono ➜ <a href="${contacts.phoneNumberURI}" target="_blank">${contacts.phoneNumber}</a></li><li>WhatsApp ➜ <a href="${contacts.whatsappURL}" target="_blank">Supporto</a></li><li>Sito Web ➜ <a href="${contacts.website}" target="_blank">${contacts.website}</a></li></ul><p>Il team di HT Apps è a tua disposizione per qualsiasi domanda o richiesta.</p>`,
		faqs: "Domande frequenti",
		feature1Text: "ULOI ti permette di creare percorsi formativi accattivanti e innovativi.",
		feature1Title: "Innovazione",
		feature2Text: "L'interfaccia user-friendly ti semplifica la vita.",
		feature2Title: "Interfaccia intuitiva",
		feature3Text: "Potrai tracciare efficacemente le tue risorse, come moduli in virtual reality (VR) e serious game.",
		feature3Title: "Tracciamento efficace",
		features: "Caratteristiche",
		featuresText: "ULOI è in grado d'integrare all'interno della tua piattaforma FAD sia videogiochi 2D e 3D che qualsiasi altro tipo di learning object.",
		fillInAllFieldsCorrectly: "Compila tutti i campi correttamente",
		firstName: "Nome",
		flipboardURL: "https://flipboard.com/@JOGroup",
		forgotPassword: "Password dimenticata?",
		forTheLatestUpdatesAccessTheOfficialDocumentation: "Per gli ultimi aggiornamenti riguardanti le API di ULOI, accedi alla documentazione tecnica ufficiale su Postman.",
		goBack: "Torna indietro",
		googleNewsURL: "https://news.google.com/s/CBIwwcfU7LMB",
		handbook: "Manuale",
		hiHowCanWeHelpYou: "Ciao, come possiamo aiutarti?",
		hide: "Nascondi",
		home: "Home",
		iAcceptThePrivacyPolicy: `Accetto la <a href="{0}" target="_blank" rel="noreferrer">politica sulla privacy</a>`,
		id: "ID",
		ifWeHaventConvincedYou: "Se non ti abbiamo convinto, leggi il parere di chi ha già provato ULOI",
		invalidEmailFormat: "Formato e-mail non valido",
		invalidLink: "Link non valido",
		invalidPhoneFormat: "Formato telefono non valido",
		invalidURLFormat: "Formato URL non valido",
		itSeemsYouAreLost: "Sembra che tu ti sia perso…",
		justNow: "Proprio adesso",
		keepReading: "Continua a leggere",
		linkExpired: "Link scaduto",
		linkedInURL: "https://it.linkedin.com/company/ht-apps",
		lms: "LMS",
		lmsConflictNameText: "Un LMS con lo stesso nome è già associato a questo amministratore.",
		lmsID: "ID LMS",
		logIn: "Accedi",
		logInToYourULOIAccount: "Accedi al tuo account ULOI",
		logOut: "Esci",
		loginFailed: "Accesso negato",
		logo: "Logo",
		message: "Messaggio",
		metaDescriptionText: "ULOI è un modulo d'interscambio dati sviluppato da HT Apps. ULOI permette a chi possiede un LMS di tracciare i learning object, come i videogiochi. Leggi.",
		name: "Nome",
		newLMS: "Nuovo LMS",
		newUserName: "Nuovo nome utente",
		newPassword: "Nuova password",
		next: "Avanti",
		noResults: "Nessun risultato",
		notifications: "Notifiche",
		numAdmins: "{0}/{1} amministratori",
		ok: "OK",
		oopsThatsNotGood: "Ops! Non va bene.",
		operatingOffice: "Sede operativa",
		operatingOfficeAddressPart1: "Via Duca degli Abruzzi, 30/36",
		operatingOfficeAddressPart2: "95127 Catania, Italia",
		or: "o",
		password: "Password",
		passwordIsRequiredWhenUserNameIsPresent: "La password è richiesta quando è presente il nome utente.",
		passwordIsTooWeak: "La password è troppo debole",
		passwordRequirementsText: "Le passwords devono contenere almeno:<ul><li>12 caratteri</li><li>1 lettera maiuscola</li><li>1 lettera minuscola</li><li>1 numero</li><li>1 carattere speciale: @.:,;'\"€£$+-_*<>°#%?!&/\\()|[]{}^§~</li></ul>",
		passwordsDontMatch: "Le password non coincidono",
		phone: "Telefono",
		postmanDocumentationURL: "/uloi-documentazione-tecnica-postman-api-test",
		privacyPolicy: "Politica sulla privacy",
		privacyPolicyURL: "https://www.ht-apps.eu/politica-sulla-privacy/",
		readOurReviews: "Leggi le nostre recensioni su {0}",
		registeredOffice: "Sede legale",
		registeredOfficeAddressPart1: "Via Mario Sangiorgi, 37",
		registeredOfficeAddressPart2: "95129 Catania, Italia",
		requestFailed: "La richiesta ha fallito.",
		requiredField: "Campo obbligatorio",
		remove: "Rimuovi",
		resetPassword: "Reimposta la password",
		save: "Salva",
		scorm: "SCORM",
		search: "Cerca",
		serverError: "Errore del server.",
		settings: "Impostazioni",
		show: "Mostra",
		showUsers: "Mostra utenti",
		signUp: "Iscriviti",
		signUpNow: "Iscriviti ora",
		signupAlreadyRegisteredText: "Un amministratore con questo indirizzo di posta è già registrato.",
		signupSuccessfulText: "La tua iscrizione è avvenuta con successo.<br />Attendi l'attivazione del tuo profilo.",
		signupUnsuccessfulText: "Ricontrollare i dati di registrazione.",
		submit: "Invia",
		success: "Successo",
		surname: "Cognome",
		termsOfUse: "Termini d'uso",
		termsOfUseURL: "https://shop.ht-apps.eu/termini-e-condizioni-di-vendita/",
		testimonial1Avatar: "/images/testimonial1_it.jpg",
		testimonial1Comment: "Grazie ad ULOI ho integrato nella nostra piattaforma FAD giochi 2D e 3D, rendendo il percorso di formazione più accattivante.",
		testimonial1FirstName: "Valerio",
		testimonial1Surname: "Trevisano",
		testimonial2Avatar: "/images/testimonial2_it.jpg",
		testimonial2Comment: "Sono rimasto colpito dalla facilità d'uso dell'ULOI e dalla compatibilità con lo standard xAPI. Grazie mille!",
		testimonial2FirstName: "Massimiliano",
		testimonial2Surname: "Greco",
		testimonial3Avatar: "/images/testimonial3_it.jpg",
		testimonial3Comment: "Se non avessi scoperto ULOI avrei dovuto cambiare approccio alla didattica. Invece l'Universal Learning Object Integrator ha reso l'integrazione di nuovi contenuti veramente semplice e rapida.",
		testimonial3FirstName: "Alda",
		testimonial3Surname: "Ferrari",
		testimonials: "Testimonial",
		theFieldsMarkedWithAnAsteriskAreRequired: "I campi contrassegnati con l'asterisco sono obbligatori",
		threeDeeAppId: "ID app 3D",
		trustpilotURL: "https://it.trustpilot.com/review/ht-apps.eu?utm_medium=trustbox&utm_source=MicroReviewCount",
		tutorial: "Tutorial",
		tutorialName: "tutorial-it.pdf",
		tutorialPath: "/handbooks/uloi-handbook-IT-rev5.4.pdf",
		type: "Tipo",
		uloi: "ULOI",
		uloiHasAlreadyBeenChosen: "L'ULOI è già stato scelto da Clienti in tutto il mondo.",
		uloiText: "L'Universal Learning Object Integrator (ULOI) di HT Apps è una piattaforma SaaS concepita per elevare l'esperienza del tuo learning management system (LMS) a un livello superiore, in quanto a versatilità ed efficacia.",
		unauthorisedRequest: "Richiesta non autorizzata.",
		url: "URL",
		userID: "ID utente",
		userNameIsRequiredWhenPasswordIsPresent: "Il nome utente è richiesto quando è presente la password.",
		universalLearningObjectIntegrator: "Universal Learning Object Integrator",
		vatNoX: "P.IVA 04125270878",
		warning: "Attenzione",
		welcomeUser: "Benvenuto/a, {0}",
		whyChooseULOI: "Perché scegliere ULOI",
		whyChooseULOIText: "Perché consente alle tue piattaforme LMS di integrare senza problemi moduli diversi tra di loro, come giochi 2D/3D e software web based, uniformando la modalità di tracciamento a quella degli standard SCORM e xAPI.",
		xapi: "xAPI",
		youtubeTutorialTitle: "ULOI - Come configurare il tuo LMS per monitorare i Learning Object",
		youtubeTutorialURL: "https://www.youtube.com/embed/bDYLY1UWmRc",
		youtubeURL: "https://www.youtube.com/watch?v=MeYE0HiKYjY",
		youMustAcceptThePrivacyPolicy: "Devi accettare la politica sulla privacy per continuare",
		yourMessageWasSentSuccessfully: "Il tuo messaggio è stato inviato con successo.",
		yourPasswordHasBeenResetSuccessfully: "La tua password è stata reimpostata con successo."
	}
}));

export default strings;
