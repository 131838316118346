import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import strings from "../strings";
import "./NotFound.css";

function NotFound() {
	const navigate = useNavigate();

	const goBack = () => navigate(-1, { replace: true });

	return (
		<Container id="no-page-container" className="home-inner-container-size text-center">
			<Row>
				<Col>
					<img alt="404" className="w-100" src="/images/404.svg" />
				</Col>
			</Row>
			<Row className="mt-4 user-select-none transparent-cursor">
				<Col className="text-white">
					<span id="no-page-text1" className="fw-bold">{strings.oopsThatsNotGood}</span><br />
					<span id="no-page-text2">{strings.itSeemsYouAreLost}</span>
				</Col>
			</Row>
			<Row className="mt-4">
				<Col>
					<Button type="button" className="home-button" onClick={goBack}>{strings.goBack}</Button>
				</Col>
			</Row>
		</Container>
	);
};

export default NotFound;
